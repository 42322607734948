import React from 'react';
import styled from 'styled-components';
import './App.css';

const Video = styled.div`
  padding: 56.25% 0 0 0;
  position: relative;
`

const VideoIframe = styled.iframe`
  position:absolute;
  top:0;
  left:0;
  width:100%;
  height:100%;
`

function App() {
    return (
        <>
            <div>
                <Video>
                    <VideoIframe src="https://player.vimeo.com/video/493831238" frameBorder="0" allow="autoplay; fullscreen"
                            allowFullScreen></VideoIframe>
                </Video>
            </div>
        </>
    );
}

export default App;
